const api = {
    login: {
        login: '/sysUser/loginByPass', // 登录
        logout: '/sysUser/logout', // 退出登录
    },

    user: {
        info: '/sysUser/currentUser', // 用户信息
    },

    // 数据管理
    dataManage: {
        total: '/count/counts', // 全部数据
        totalSale: '/count/orders', // 销售数据
        business: '/count/operatorPage', // 商家统计
        goods: '/count/goodsPage', // 商品统计
        store: '/count/storePage', // 门店统计
        machine: '/count/devicePage', // 货柜统计
    },

    // 用户管理
    userManage: {
        list: '/sysUser/page', // 用户列表
        create: '/sysUser/insert', // 新建用户
        detail: '/sysUser/detail', // 用户详情
        edit: '/sysUser/update', // 编辑用户
        openClose: '/sysUser/delete', // 启用/停用
        blackEecovery: '/sysUser/blacklist', // 拉黑/恢复
        changePwd: '/sysUser/updatePassByAdmin', // 修改密码
    },

    // 设备管理
    machine: {
        list: '/device/page', // 设备列表
        create: '/device/insert', // 新建设备
        detail: '/device/detail', // 设备详情
        edit: '/device/update', // 编辑设备
    },

    // 唯一编码管理
    qrCode: {
        list: '/device/noPage', // 唯一编码列表
    },

    // 柜门贴纸模板
    machineSticker: {
        list: '/deviceSticker/page', // 柜门贴纸列表
        add: '/deviceSticker/insert', // 添加柜门贴纸模板
        delete: '/deviceSticker/delete', // 删除柜门贴纸模板
        download: '/device/downloadSticker', // 下载柜门贴纸
    },

    // 门店
    store: {
        list: '/store/page', // 门店列表
    },

    orders: {
        list: '/orders/page', // 订单列表
        cancelOrders: '/orders/cancelOrder', // 取消订单
        reminderOrders: '/orders/scorePayOrder', // 催单
        exportOrders: '/orders/exportExcel', // 导出
        detail: '/orders/detail', // 订单详情
    },

    financeManage: {
        list: '/withdrawalRequest/page', // 申请列表
        examine: '/withdrawalRequest/status', // 审核
    },

    upload: {
        upload: '/upload/file', // 上传文件
    }
}

export default api