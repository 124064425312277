import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import mutations from './mutations'
import actions from './actions'
import getters from './getters'

// 用户管理
import userManage from './userManage/index'
// 设备管理
import machineManage from './machineManage/index'
// 财务管理
import financeManage from './financeManage/index'

const state = {
	userInfo: {
		avatar: '',
		nickname: '',
		username: ''
	}, // 用户信息

	// 菜单（权限）
	menu: [{
			key: 'DataManage',
			title: '数据管理',
			icon: 'pie-chart',
			auth: 1,
			children: [{
				key: 'DataOverview',
				title: '数据概览',
				icon: 'pie-chart',
				auth: 1,
				pageName: 'dataOverview'
			}]
		},
		{
			key: 'UsersManage',
			title: '用户管理',
			icon: 'team',
			auth: 1,
			children: [{
					key: 'OrdinaryUsers',
					title: '普通用户管理',
					icon: 'user',
					auth: 1,
					pageName: 'ordinaryUsers'
				},
				{
					key: 'BusinessUsers',
					title: '商家用户管理',
					icon: 'user',
					auth: 1,
					pageName: 'businessUsers'
				},
			]
		},
		{
			key: 'MachineManages',
			title: '设备管理',
			icon: 'hdd',
			auth: 1,
			children: [{
					key: 'MachineManage',
					title: '设备管理',
					icon: 'hdd',
					auth: 1,
					pageName: 'machineManage'
				},
				{
					key: 'SnManage',
					title: '唯一编码管理',
					icon: 'barcode',
					auth: 1,
					pageName: 'snManage'
				},
				{
					key: 'StickerManage',
					title: '柜门贴纸模板管理',
					icon: 'barcode',
					auth: 1,
					pageName: 'stickerManage'
				},
			]
		},
		{
			key: 'OrdersManages',
			title: '订单管理',
			icon: 'file-text',
			auth: 1,
			children: [{
					key: 'OrdersManage',
					title: '订单管理',
					icon: 'file-text',
					auth: 1,
					pageName: 'ordersManage'
				},
				{
					key: 'OrdersStatistics',
					title: '订单统计',
					icon: 'bar-chart',
					auth: 1,
					pageName: 'ordersStatistics'
				},
			]
		},
		{
			key: 'FinanceManage',
			title: '财务管理',
			icon: 'money-collect',
			auth: 1,
			children: [{
				key: 'WithdrawalAudit',
				title: '提现申请',
				icon: 'audit',
				auth: 1,
				pageName: 'withdrawalAudit'
			}, ]
		},
		{
			key: 'AuthManage',
			title: '权限管理',
			icon: 'lock',
			auth: 1,
			children: [{
					key: 'AccountManage',
					title: '账号管理',
					icon: 'idcard',
					auth: 1,
					pageName: 'accountManage'
				},
				{
					key: 'RoleManage',
					title: '角色管理',
					icon: 'user',
					auth: 1,
					pageName: 'roleManage'
				},
			]
		},
		{
			key: 'SystemManage',
			title: '系统管理',
			icon: 'desktop',
			auth: 1,
			children: [{
				key: 'SystemSetting',
				title: '系统配置',
				icon: 'setting',
				auth: 1,
				pageName: 'systemSetting'
			}]
		},
	]
}

const store = new Vuex.Store({
	state,
	mutations,
	actions,
	getters,
	modules: {
		userManage,
		machineManage,
		financeManage
	}
})
export default store
