export default {
    // 保存用户信息
    saveUserInfoAsync(context, payload) {
        return new Promise((resolve, reject) => {
            context.commit('saveUserInfo', payload)
        })
    },

    // 菜单权限
    setMenuAuthAsync(context, payload) {
        return new Promise((resolve, reject) => {
            context.commit('setMenuAuth', payload)
        })
    }
}