import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.less';

const MyComponents = {};
MyComponents.install = (Vue) => {
    // 自定义组件
	const comps = []

	for (let index = 0; index < comps.length; index++) {
		Vue.component(comps[index].name, comps[index].component);
	}

    // antd组件
    Vue.use(Antd);
}

export default MyComponents
