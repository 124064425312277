<template>
    <div id="app">
        <a-config-provider :locale="locale">
            <router-view></router-view>
        </a-config-provider>
    </div>
</template>

<script>
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';

moment.locale('zh-cn');

import { getUserInfo } from 'network/user'

import { mapActions } from 'vuex'

export default {
    name: "App",
    data() {
        return {
            locale: zh_CN,
        }
    },
    created() {
        this._getUserInfo()
    },
    methods: {
        // ---------------事件处理函数---------------
        ...mapActions(['saveUserInfoAsync']),

        // ----------------网络请求--------------
        // 获取用户信息
        _getUserInfo() {
            getUserInfo().then(res => {
                const data = res.data;

                const userInfo = {
                    nickname: data.realname,
                    username: data.username
                }

                this.saveUserInfoAsync(userInfo)
            }).catch(err => {})
        }
    }
}
</script>

<style>
@import "assets/css/base.css";

</style>
