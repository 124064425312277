import actions from './actions'
import mutations from './mutations'
import getters from './getters'

const state = () => ({
    module: 'machineManage',

    editMachineId: '',
    bindOrEdit: 1, // 1：新建/编辑设备；2：绑定/解绑
})

const machineManage = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}

export default machineManage