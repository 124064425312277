import Vue from 'vue'
import VueRouter from 'vue-router'

import { getCookie } from '../common/utils.js';

// 防止进入同一页面时报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

// 数据管理
import DataManagePaths from './dataManage' 
// 用户管理
import UserManagePaths from './userManage'
// 设备管理
import MachineManagePaths from './machineManage'
// 订单管理
import OrdersManagePaths from './ordersManage'
// 财务管理
import FinanceManagePaths from './financeManage'
// 权限管理
import AuthManagePaths from './authManage'
// 系统管理
import SystemManagePaths from './systemManage'

const Login = () => import('../views/login/Login.vue')
const Index = () => import('../views/index/Index.vue')

const routes = [
	{
		path: '/',
		redirect: '/index'
	},
	{
		path: '/login',
		component: Login,
		name: 'login',
        meta: {
            title: '登录'
        }
	},
    {
		path: '/index',
		component: Index,
		name: 'index',
        meta: {
            title: '首页'
        },
        children: [
            // 数据管理
            ...DataManagePaths.paths,
            // 用户管理
            ...UserManagePaths.paths,
            // 设备管理
            ...MachineManagePaths.paths,
            // 订单管理
            ...OrdersManagePaths.paths,
            // 财务管理
            ...FinanceManagePaths.paths,
            // 权限管理
            ...AuthManagePaths.paths,
            // 系统管理
            ...SystemManagePaths.paths
        ]
	}
]

const router = new VueRouter({
	mode: 'hash',
	// mode: 'history',
	routes
})

router.beforeEach((to, from, next) => {
    // 判断token是否存在
    if(to.name === 'login'){
        next()
    } else {
        let token = { token: '' };
        getCookie(['token'], token);

        if (!token.token) {
            token.token = sessionStorage.getItem('token') || ''
        }

    
        token.token ? next() : next({
            replace: true,
            name: 'login'
        })
    }
})

export default router
