import api from './api'
import { request } from './request'

// 获取用户信息
function getUserInfo() {
    return request({
        url: api.user.info,
        method: 'post'
    })
}

export {
    getUserInfo
}