export default {
	// 用户信息
	userInfo(state) {
		return state.userInfo
	},

	// 菜单
	menuList(state) {
        let menu = setMenu(state.menu)

		return menu
	}
}

// 设置有权限的菜单
function setMenu(list) {
    let menu = [];

    for (let i = 0; i < list.length; i++) {
        if (list[i].auth === 1) {
            menu[i] = list[i];

            menu[i].children && menu[i].children.length > 0 && (menu[i].children = setMenu(menu[i].children))
        }
    }

    return menu
}