const MachineManage = () => import('../views/machineManage/MachineManage.vue')
const SnManage = () => import('../views/machineManage/SnManage.vue')
const StickerManage = () => import('../views/machineManage/StickerManage.vue')

const MachineManagePaths = {
    paths: [
        {
            path: '/machineManage',
            component: MachineManage,
            name: 'machineManage',
            meta: {
                title: '设备管理'
            }
        },
        {
            path: '/snManage',
            component: SnManage,
            name: 'snManage',
            meta: {
                title: '唯一编码管理'
            }
        },
        {
            path: '/stickerManage',
            component: StickerManage,
            name: 'stickerManage',
            meta: {
                title: '柜门贴纸模板管理'
            }
        }
    ]
}

export default MachineManagePaths