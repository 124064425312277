const OrdersManage = () => import('../views/ordersManage/OrdersManage.vue') // 订单管理
const OrderDetail = () => import('../views/ordersManage/OrderDetail.vue') // 订单详情
const OrdersStatistics = () => import('../views/ordersManage/OrdersStatistics.vue') // 订单统计

const OrdersManagePaths = {
    paths: [
        {
            path: '/ordersManage',
            component: OrdersManage,
            name: 'ordersManage',
            meta: {
                title: '订单管理'
            }
        },
        {
            path: '/orderDetail',
            component: OrderDetail,
            name: 'orderDetail',
            meta: {
                title: '订单详情'
            }
        },
        {
            path: '/ordersStatistics',
            component: OrdersStatistics,
            name: 'ordersStatistics',
            meta: {
                title: '订单统计'
            }
        }
    ]
}

export default OrdersManagePaths