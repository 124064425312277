export default {
    // 设置编辑的设备id
    setEditMachineIdAsync(context, payload) {
        return new Promise((resolve, reject) => {
            context.commit('setEditMachineId', payload)
        })
    },
    // 设置操作类型
    setBindOrEditAsync(context, payload) {
        return new Promise((resolve, reject) => {
            context.commit('setBindOrEdit', payload)
        })
    }
}