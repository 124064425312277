import actions from './actions'
import mutations from './mutations'
import getters from './getters'

const state = () => ({
    module: 'financeManage',

    auditInfo: {},
})

const financeManage = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}

export default financeManage