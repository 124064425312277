const AccountManage = () => import('../views/authManage/AccountManage.vue') // 账号管理
const RoleManage = () => import('../views/authManage/RoleManage.vue') // 角色管理

const AuthManagePaths = {
    paths: [
        {
            path: '/accountManage',
            component: AccountManage,
            name: 'accountManage',
            meta: {
                title: '账号管理'
            }
        },
        {
            path: '/roleManage',
            component: RoleManage,
            name: 'roleManage',
            meta: {
                title: '角色管理'
            }
        }
    ]
}

export default AuthManagePaths