const DataOverview = () => import('../views/dataManage/DataOverview.vue'); // 数据概况

const DataManagePaths = {
    paths: [
        {
            path: '/dataOverview',
            component: DataOverview,
            name: 'dataOverview',
            meta: {
                title: '数据概况'
            }
        }
    ]
}

export default DataManagePaths
