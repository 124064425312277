const WithdrawalAudit = () => import('../views/financeManage/WithdrawalAudit.vue') // 提现申请

const FinanceManagePaths = {
    paths: [
        {
            path: '/withdrawalAudit',
            component: WithdrawalAudit,
            name: 'withdrawalAudit',
            meta: {
                title: '提现申请'
            }
        }
    ]
}

export default FinanceManagePaths