// 防抖函数 debounce
function debounce(func, delay) {
	let timer = null;
	return function (...args) {
		if (timer) clearTimeout(timer);
		timer = setTimeout(() => {
			func.apply(this, args);
		}, delay);
	};
}

// 格式化时间
function formatDate(date, fmt) {
	// 1.获取年份
	if (/(y+)/.test(fmt)) {
		fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
	}

	// 2.获取其它时间
	let o = {
		'M+': date.getMonth() + 1,
		'd+': date.getDate(),
		'h+': date.getHours(),
		'm+': date.getMinutes(),
		's+': date.getSeconds(),
	};
	for (let k in o) {
		if (new RegExp(`(${k})`).test(fmt)) {
			let str = o[k] + '';
			fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str));
		}
	}
	return fmt;
}

function padLeftZero(str) {
	return ('00' + str).substr(str.length);
}

// 获取cookie值
function getCookie(keys, that) {
	if (document.cookie.length > 0) {
		let arr = document.cookie.split('; ');

		arr.forEach(item => {
			let arr2 = item.split('=');
			// 查找到对应的key，然后赋值
			keys.forEach(key => {
				arr2[0] === key && (that[key] = arr2[1])
			})
		})
	}
}
// 设置cookie值
function setCookie(key, data, exTime) {
	let exDate = new Date();

    let expires = ''

    if (exTime){
        exDate.setTime(exTime)
        expires = exDate.toUTCString(); // 设置过期时间
    } else {
        expires = -1
    }

	document.cookie = `${ key }=${ data };expires=${ expires };path=/`;
}
// 删除cookie
function clearCookie(key) {
	let day = 24 * 60 * 60 * 1000;
	setCookie(key, '', -day)
}

// 获取请求头
function getHeader() {
    let token = {
		token: ''
	};
	getCookie(['token'], token)

    if (!token.token) {
        token.token = sessionStorage.getItem('token') || ''
    }

    return {
		"Authorization": token.token || ''
    }
}

export {
    getHeader,
    debounce,
    formatDate,
    getCookie,
    setCookie,
    clearCookie
}