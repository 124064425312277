import axios from 'axios';

import router from '../router';

import {
	message
} from 'ant-design-vue';

import {
	baseUrl
} from './config.js';
import {
	getCookie
} from 'common/utils.js';

export function request(config, repeat = false, count = 1) {
	let token = {
		token: ''
	};
	getCookie(['token'], token)

	if (!token.token) {
		token.token = sessionStorage.getItem('token') || ''
	}

	// 不需要使用token的接口
	let notUseToken = [
		'/sysUser/loginByPass'
	]

	// 1.创建axios的实例
	const instance = axios.create({
		// baseURL: '/myApi',
		baseURL: baseUrl,
		headers: {
			'Content-Type': 'application/json',
			"Authorization": notUseToken.indexOf(config.url) >= 0 ? '' : token.token
		},
		timeout: 10000
	})

	// 2.axios的拦截器
	// 2.1.请求拦截的作用
	instance.interceptors.request.use(config => {
		// 添加取消标记
		config.cancelToken = new axios.CancelToken(cancel => {
			window.axiosCancel.push({
				cancel
			})
		})

		return config
	}, err => {})

	// 2.2.响应拦截
	instance.interceptors.response.use(res => {
		const data = res.data;

        // stream流文件下载
        if (['application/vnd.ms-excel', 'application/octet-stream'].indexOf(data.type) >= 0) {
            return data
        }
		
		if (data.status == -2) { // token错误
			router.replace({
				name: 'login'
			})

			return Promise.reject(data);
		} else if (data.status == 1) { // 操作成功
			return data
		} else if (data.status == 500) {
			return Promise.reject(data);
		} else {
			message.error(data.message);
			return Promise.reject(data);
		}
	}, err => {
		// 未登录
		if (err.response && err.response.data.code == 401) {
			router.replace({
				name: 'login'
			})
		}

		// 请求超时
		if (err.code == 'ECONNABORTED') {
			// 不需要重连
			if (!repeat) return Promise.reject(err);

			// 重连次数大于等于5次不再重连
			if (count >= 5) return Promise.reject(err);

			// 超时重连
			return request(config, repeat, count + 1)
		} else if (err.response) {
			const msg = err.response.data.message;
		}

		return Promise.reject(err);
	})

	// 3.发送真正的网络请求
	return instance(config)
}
