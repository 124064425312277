import actions from './actions'
import mutations from './mutations'
import getters from './getters'

const state = () => ({
    module: 'userMange',

    editUserId: '',
})

const userManage = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}

export default userManage