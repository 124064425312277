import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import {
	baseUrl,
    fileUrl
} from 'network/config.js';

Vue.prototype.$baseUrl = baseUrl;
Vue.prototype.$fileUrl = fileUrl;

// 全局引入组件
import MyComponents from './components/install';
Vue.use(MyComponents)

// 记录请求axios token，用于取消请求
window.axiosCancel = [];

// 全局配置取消请求方法
Vue.prototype.$cancelAxios = () => {
	// 获取缓存的 请求取消标识 数组，取消所有关联的请求
	let cancelArr = window.axiosCancel;
	cancelArr.forEach((el, index) => {
		el.cancel("取消了请求") // 在失败函数中返回这里自定义的错误信息
		delete window.axiosCancel[index]
	})
}

Vue.config.productionTip = false

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
