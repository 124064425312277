const SystemSetting = () => import('../views/systemManage/SystemSetting.vue') // 系统配置

const SystemManangePaths = {
    paths: [
        {
            path: '/systemSetting',
            component: SystemSetting,
            name: 'systemSetting',
            meta: {
                title: '系统配置'
            }
        }
    ]
}

export default SystemManangePaths