export default {
    // 保存用户信息
    saveUserInfo(state, payload) {
        for (const key in payload) {
            if (Object.hasOwnProperty.call(payload, key)) {
                state.userInfo[ key ] = payload[key];
            }
        }
    },

    // 菜单权限
    setMenuAuth(state, payload){
        
    }
}