// 用户管理
const OrdinaryUsers = () => import('../views/usersManage/OrdinaryUsers.vue'); // 普通用户管理
const BusinessUsers = () => import('../views/usersManage/BusinessUsers.vue'); // 商家用户管理

const UserManagePaths = {
    paths: [
        {
            path: '/ordinaryUsers',
            component: OrdinaryUsers,
            name: 'ordinaryUsers',
            meta: {
                title: '普通用户管理'
            }
        },
        {
            path: '/businessUsers',
            component: BusinessUsers,
            name: 'businessUsers',
            meta: {
                title: '商家用户管理'
            }
        },
    ]
}

export default UserManagePaths;